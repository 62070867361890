import { FC, useState } from "react";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import "./Dashboard.scss";
import { useCoupons } from "../../modules/coupon/coupon.query";
import { useUsers } from "../../modules/user/user.query";
import { useOrders } from "../../modules/order/order.fn";
import { getTimeToCompleteSorvey, getUsersByMonth } from "./Dashboard.fn";
import {
  getSortedValueDrivers,
  useNarrative,
} from "../../modules/narrative/narrative.fn";
import { LoginHeader } from "../../modules/landing/navigation/login-header";
import {
  getGenders,
  getIncome,
  useFingerprintData,
} from "../../modules/fingerprintData/fingerprintData.fn";
import {
  getByCoupon,
  getUserIdsByCoupon,
} from "../../modules/coupon/coupon.fn";
import { Label } from "./Label/Label";

export const Dashboard: FC = () => {
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = (props: any) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, percent, name, index } =
      props;

    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <foreignObject x={x} y={y} width={index === 2 ? 130 : 80} height={28}>
        <div
          style={{
            lineHeight: 1,
            fontSize: 12,
            color: "grey",
            background: "#fff",
            border: "1px solid grey",
            borderRadius: 8,
            padding: 5,
            whiteSpace: "nowrap",
          }}
        >{`${name} ${(percent * 100).toFixed(0)}%`}</div>
      </foreignObject>
    );
  };

  const [selectedCoupons, setSelectedCoupons] = useState<
    { id: number; code: string }[]
  >([]);

  const { data: coupons, isLoading: isLoadingCoupons } = useCoupons();

  const { orders } = useOrders();

  const { data: users } = useUsers();

  const { data: narrativeData, isLoading: isLoadingNarrative } = useNarrative();

  const { data: fingerprintData, isLoading: isLoadingFingerprint } =
    useFingerprintData();

  const ids = getUserIdsByCoupon(
    orders,
    selectedCoupons.map((c) => c.id)
  );

  const usersByCoupon =
    selectedCoupons.length === 0
      ? users
      : users?.filter((u: any) => ids.includes(u.id));

  const usersData = getUsersByMonth(usersByCoupon);

  const narrativeDataByCoupon = getByCoupon(narrativeData, ids);

  const fingerprintDataByCoupon = getByCoupon(fingerprintData, ids);

  const rankedValues = narrativeDataByCoupon?.map((d: any) =>
    d?.rankedValues?.split(",")
  );

  const coreRankedValues = narrativeDataByCoupon?.map((d: any) => {
    const arr = d?.rankedValues?.split(",");
    return [arr?.[0]];
  });

  const archetypes = narrativeDataByCoupon?.map((d: any) => [d?.archetype]);

  const outcomeValues = narrativeDataByCoupon?.map((d: any) => {
    const values = d?.rankedValues?.split(",");

    if (values && values.length) {
      return [values[values?.length - 1]];
    }
  });

  const mostCommonValueDrivers = getSortedValueDrivers(rankedValues);

  const mostCommonCoreValueDrivers = getSortedValueDrivers(coreRankedValues);

  const mostCommonArchetypes = getSortedValueDrivers(archetypes);

  const mostCommonOutcomeValueDrivers = getSortedValueDrivers(outcomeValues);

  const genders = getGenders(fingerprintDataByCoupon);

  const income = getIncome(fingerprintDataByCoupon);

  const timeToComplete = getTimeToCompleteSorvey(narrativeDataByCoupon || []);

  return (
    <div className="dashboard-wrapper">
      <LoginHeader />

      <div>
        <div className="dashboard-panel">
          <label>
            <span className="filter-label">Filter by coupon:</span>

            <select
              onChange={(e) => {
                const code = e.target.value;

                const coupon = coupons?.find((c) => c.code === code);

                // Clear filter when selecting "All" groups
                if (code === "All") {
                  setSelectedCoupons([]);

                  return;
                }

                // Early return undefined
                if (!coupon) {
                  return;
                }

                // Coupon already in filters
                if (selectedCoupons.map((c) => c.code).includes(code)) {
                  return;
                }

                setSelectedCoupons([...selectedCoupons, coupon]);
              }}
            >
              <option key={0} value="All">
                {isLoadingCoupons ? "Loading..." : "All"}
              </option>

              {coupons?.map((c) => (
                <option key={c.id} value={c.code}>
                  {c.code}
                </option>
              ))}
            </select>
          </label>

          {!!selectedCoupons.length && (
            <div className="dashboard-coupons">
              <span className="filter-label">Selected groups:</span>

              {selectedCoupons.map((coupon) => (
                <div
                  key={coupon.id}
                  className="dashboard-coupon"
                  onClick={() => {
                    const [...coupons] = selectedCoupons;

                    const index = coupons.findIndex((c) => c.id === coupon.id);

                    coupons.splice(index, 1);

                    setSelectedCoupons(coupons);
                  }}
                >
                  {coupon.code} <span>&#10060;</span>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="dashboard-layout">
          <div className="chart-row"></div>

          <div className="chart-row">
            <h2>
              Total users: <span>{usersByCoupon?.length}</span>
            </h2>

            <div>
              <ResponsiveContainer height={300}>
                <AreaChart
                  data={usersData}
                  {...{
                    overflow: "visible",
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />

                  <XAxis dataKey="month" angle={-25} dy={20} />

                  <YAxis />

                  <Tooltip />

                  <Area
                    type="monotone"
                    dataKey="Users"
                    stroke="#orange"
                    fill="#3c78e6"
                    label={Label}
                    dot
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="chart-row">
            <h2>Archetypes:</h2>

            <ResponsiveContainer height={300}>
              {isLoadingNarrative ? (
                <div>Loading...</div>
              ) : (
                <BarChart
                  data={mostCommonArchetypes}
                  {...{
                    overflow: "visible",
                  }}
                >
                  <Bar dataKey="value" fill="#00C49F">
                    <LabelList dataKey="value" position="middle" fill="#fff" />
                  </Bar>

                  <XAxis
                    dataKey="name"
                    axisLine={false}
                    tickLine={false}
                    interval={0}
                    angle={-20}
                    dy={10}
                  />
                </BarChart>
              )}
            </ResponsiveContainer>
          </div>

          <div className="chart-row">
            <h2>Gender:</h2>
            <ResponsiveContainer height={300}>
              {isLoadingFingerprint ? (
                <div>Loading...</div>
              ) : (
                <PieChart
                  {...{
                    overflow: "visible",
                  }}
                >
                  <Pie
                    data={genders}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {genders?.map((_: any, index: number) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              )}
            </ResponsiveContainer>
          </div>

          <div className="chart-row">
            <h2>Value drivers:</h2>

            <ResponsiveContainer height={300}>
              {isLoadingNarrative ? (
                <div>Loading...</div>
              ) : (
                <BarChart
                  data={mostCommonValueDrivers}
                  {...{
                    overflow: "visible",
                  }}
                >
                  <Bar dataKey="value" fill="#3c78e6">
                    <LabelList dataKey="value" position="middle" fill="#fff" />
                  </Bar>

                  <XAxis
                    dataKey="name"
                    axisLine={false}
                    tickLine={false}
                    interval={0}
                    angle={-20}
                    dy={10}
                  />
                </BarChart>
              )}
            </ResponsiveContainer>
          </div>

          <div className="chart-row">
            <h2>Income:</h2>

            <ResponsiveContainer height={400}>
              {isLoadingFingerprint ? (
                <div>Loading...</div>
              ) : (
                <BarChart
                  data={income}
                  layout="vertical"
                  {...{
                    overflow: "visible",
                  }}
                >
                  <Bar dataKey="value" fill="#8884d8">
                    <LabelList dataKey="value" position="middle" fill="#fff" />
                  </Bar>

                  <XAxis type="number" axisLine={false} tick={false} />

                  <YAxis
                    dataKey="name"
                    type="category"
                    tickLine={false}
                    style={{ whiteSpace: "nowrap" }}
                  />
                </BarChart>
              )}
            </ResponsiveContainer>
          </div>

          <div className="chart-row">
            <h2>Core value drivers:</h2>

            <ResponsiveContainer height={300}>
              {isLoadingNarrative ? (
                <div>Loading...</div>
              ) : (
                <BarChart
                  data={mostCommonCoreValueDrivers}
                  {...{
                    overflow: "visible",
                  }}
                >
                  <Bar dataKey="value" fill="orange">
                    <LabelList dataKey="value" position="middle" fill="#fff" />
                  </Bar>

                  <XAxis
                    dataKey="name"
                    axisLine={false}
                    tickLine={false}
                    interval={0}
                    angle={-20}
                    dy={10}
                  />
                </BarChart>
              )}
            </ResponsiveContainer>
          </div>

          <div className="chart-row">
            <h2>Time to complete:</h2>

            <ResponsiveContainer height={300}>
              {isLoadingNarrative ? (
                <div>Loading...</div>
              ) : (
                <BarChart
                  data={timeToComplete}
                  {...{
                    overflow: "visible",
                  }}
                >
                  <Bar dataKey="value" fill="coral">
                    <LabelList dataKey="value" position="middle" fill="#fff" />
                  </Bar>

                  <XAxis
                    dataKey="name"
                    axisLine={false}
                    tickLine={false}
                    interval={0}
                    angle={-20}
                    dy={10}
                  />
                </BarChart>
              )}
            </ResponsiveContainer>
          </div>

          <div className="chart-row">
            <h2>Outcome value drivers:</h2>

            <ResponsiveContainer height={300}>
              {isLoadingNarrative ? (
                <div>Loading...</div>
              ) : (
                <BarChart
                  data={mostCommonOutcomeValueDrivers}
                  {...{
                    overflow: "visible",
                  }}
                >
                  <Bar dataKey="value" fill="#00C49F">
                    <LabelList dataKey="value" position="middle" fill="#fff" />
                  </Bar>

                  <XAxis
                    dataKey="name"
                    axisLine={false}
                    tickLine={false}
                    interval={0}
                    angle={-20}
                    dy={10}
                  />
                </BarChart>
              )}
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};
