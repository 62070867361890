import { useQuery } from "react-query";

type WorkGroupsPayload = {
  target_user_id: number;
  amount_best: number;
  amount_worse: number;
  data: any[];
};

type CompatibilityMatrixPayload = {
  workGroupData?: any[];
  users: number;
};

export const useIndividualCompatibility = (
  { target_user_id, amount_best, amount_worse, data }: WorkGroupsPayload,
  selectedUserForIndividualReport: string
) => {
  return useQuery({
    queryKey: ["work-groups", selectedUserForIndividualReport, data?.length],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_AI_BASE_URL}/dashboard/compatibility_by_user`,
        {
          method: "POST",
          headers: { "Content-type": "application/json" },
          body: JSON.stringify({
            target_user_id,
            amount_best,
            amount_worse,
            data,
          }),
        }
      );

      const result = await response.json();

      return result;
    },
    // Ensure we have compatibility_score (it's added later)
    enabled: !!data.filter((d) => d?.compatibility_score).length,
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};

export const useCompatibilityMatrix = ({
  workGroupData,
  users,
}: CompatibilityMatrixPayload) => {
  return useQuery({
    queryKey: ["compatibility-matrix", users],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_AI_BASE_URL}/dashboard/compatibility_matrix`,
        {
          method: "POST",
          headers: { "Content-type": "application/json" },
          body: JSON.stringify({
            data: workGroupData,
          }),
        }
      );

      const data = await response.json();

      return data;
    },
    // enabled: !!workGroupData?.length,
    enabled: false,
  });
};
