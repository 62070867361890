import { FC, useMemo, useState } from "react";
import cn from "classnames";
import {
  useCompatibilityMatrix,
  useIndividualCompatibility,
} from "../../modules/deduction/deduction.fn";
import { LoginHeader } from "../../modules/landing/navigation/login-header";
import { useUsers } from "../../modules/user/user.query";
import {
  getByCoupon,
  getUserIdsByCoupon,
} from "../../modules/coupon/coupon.fn";
import { useOrders } from "../../modules/order/order.fn";
import { useNarrative } from "../../modules/narrative/narrative.fn";
import { useCoupons } from "../../modules/coupon/coupon.query";
import "./CompatibilityMatrix.scss";
import { Button } from "react-bootstrap";
import {
  Cell,
  LabelList,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  XAxis,
  YAxis,
  ZAxis,
} from "recharts";

function getBackground(c: number) {
  if (c >= 76) {
    return "#82aaf9";
  }

  if (c >= 51) {
    return "#33337f";
  }

  if (c >= 26) {
    return "#ffe200";
  }

  if (c === -1) {
    return "#b6b8b9";
  }

  return "#ff8c42";
}

export const CompatibilityMatrix: FC = () => {
  const [selectedCoupons, setSelectedCoupons] = useState<
    { id: number; code: string }[]
  >([]);

  const [selectedUsers, setSelectedUsers] = useState<
    { id: number; name: string }[]
  >([]);

  const [selectedCompatibility, setSelectedCompatibility] = useState(
    [] as any[]
  );

  const { orders } = useOrders();

  const { data: users } = useUsers();

  const { data: coupons, isLoading: isLoadingCoupons } = useCoupons();

  const { data: narrativeData } = useNarrative();

  const ids = getUserIdsByCoupon(
    orders,
    selectedCoupons.map((c) => c.id)
  );

  const usersByCoupon =
    selectedCoupons.length === 0
      ? users
      : users?.filter((u: any) => ids.includes(u.id));

  const usersSortedByFullName = usersByCoupon?.sort((a: any, b: any) => {
    const nameA = a?.fullName?.toLowerCase();
    const nameB = b?.fullName?.toLowerCase();

    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  });

  const narrativeDataByCoupon = getByCoupon(narrativeData, ids);

  const compatibilityMatrixPayload = narrativeDataByCoupon
    // Find narrative data for the selected users
    ?.filter((d: any) => {
      const ids = selectedUsers.map((u) => u.id);

      return ids.includes(d.userId);
    })
    // Filter users with all narrative data
    ?.filter((d: any) => {
      return (
        d.userId &&
        d.rankedValues &&
        d.valuesPhilosophy &&
        d.archetype &&
        d.archetypeExplanation &&
        d.triggers &&
        d.triggersExplanation
      );
    })
    // Transform payload
    .map((d: any) => {
      return {
        id: d.userId,
        userId: d.userId,
        rankedValues: d.rankedValues,
        valuesPhilosophy: d.valuesPhilosophy,
        archetype: d.archetype,
        archetypeExplanation: d.archetypeExplanation,
        triggers: d.triggers,
        triggersExplanation: d.triggersExplanation,
      };
    })
    // Don't allow multiple narrative records, choose the latest
    ?.reduce((acc, cur) => {
      const hasThisUserAlready = !!acc.find((el) => el.userId === cur.userId);

      if (!hasThisUserAlready) {
        acc.push(cur);
      }

      return acc;
    }, [] as any[]);

  const targetUserForIndividualReport = users?.find(
    (u: any) =>
      u.id === selectedCompatibility?.find((d) => d.compatibility === -1)?.id
  );

  const selectedTableRowIndex = compatibilityMatrixPayload?.findIndex(
    (p: any) => p?.userId === targetUserForIndividualReport?.id
  );

  const {
    data: compatibilityMatrixData,
    isLoading: isLoadingCompatibilityMatrix,
    refetch: fetchCompatibilityMatrix,
  } = useCompatibilityMatrix({
    workGroupData: compatibilityMatrixPayload,
    users: compatibilityMatrixPayload?.length || 0,
  });

  const compatibilityMatrix = compatibilityMatrixData?.matrix;

  const individualReportPayload = useMemo(
    () =>
      compatibilityMatrixPayload?.map((mp) => {
        // Find the target user compatibilities from the compatibility matrix
        const compatibilities = compatibilityMatrix?.find(
          (m: any) => !!m[targetUserForIndividualReport?.id]
        )?.[targetUserForIndividualReport?.id];

        // Get the compatibility score for each user in the individual report payload
        const compatibility_score = compatibilities?.find(
          (c: any) => c?.id === mp?.userId
        )?.compatibility;

        return { ...mp, compatibility_score };
      }),
    [
      compatibilityMatrix,
      compatibilityMatrixPayload,
      targetUserForIndividualReport,
    ]
  );

  const {
    data: individualCompatibility,
    isLoading: isLoadingIndividualCompatibility,
  } = useIndividualCompatibility(
    {
      target_user_id: targetUserForIndividualReport?.id,
      amount_best: individualReportPayload?.length || 0,
      amount_worse: individualReportPayload?.length || 0,
      data: individualReportPayload || [],
    },
    targetUserForIndividualReport
  );

  const args = {
    activeShape: {
      fill: "red",
    },
    activeIndex: undefined,
  };

  const individualReportChartData = selectedCompatibility
    // .filter((d: any) => d.compatibility >= 0)
    .map((d, index) => {
      if (d.compatibility === -1) {
        return {
          ...d,
          compatibility: 100,
          color: getBackground(100),
          user: users.find((u: any) => u.id === d.id)?.fullName,
          Yposition: 1 / selectedCompatibility.length,
        };
      }

      return {
        ...d,
        color: getBackground(d.compatibility),
        user: users.find((u: any) => u.id === d.id)?.fullName,
        Yposition:
          (1 / selectedCompatibility.length) * index +
          1 / selectedCompatibility.length,
      };
    });

  const compatibilityByUser = Object.entries(
    individualCompatibility?.compatibility || {}
  )
    ?.map(([key, value]: any) => {
      const score = value?.score;
      const description = value?.compatibility_description;

      const user: any = usersByCoupon?.find((f: any) => f.id == key)?.fullName;

      return { user, score, description };
    })
    ?.sort((a, b) => b.score - a.score);

  return (
    <div className="dashboard-wrapper">
      <LoginHeader />

      <div>
        <div className="dashboard-panel">
          <label>
            <span className="filter-label">Filter by coupon:</span>

            <select
              onChange={(e) => {
                const code = e.target.value;

                const coupon = coupons?.find((c) => c.code === code);

                // Clear filter when selecting "All" groups
                if (code === "All") {
                  setSelectedCoupons([]);

                  return;
                }

                // Early return undefined
                if (!coupon) {
                  return;
                }

                // Coupon already in filters
                if (selectedCoupons.map((c) => c.code).includes(code)) {
                  return;
                }

                setSelectedCoupons([...selectedCoupons, coupon]);
              }}
            >
              <option key={0} value="All">
                {isLoadingCoupons ? "Loading..." : "All"}
              </option>

              {coupons?.map((c) => (
                <option key={c.id} value={c.code}>
                  {c.code}
                </option>
              ))}
            </select>
          </label>

          {!!selectedCoupons.length && (
            <div className="dashboard-coupons">
              <span className="filter-label">Selected groups:</span>

              {selectedCoupons.map((coupon) => (
                <div
                  key={coupon.id}
                  className="dashboard-coupon"
                  onClick={() => {
                    const [...coupons] = selectedCoupons;

                    const index = coupons.findIndex((c) => c.id === coupon.id);

                    coupons.splice(index, 1);

                    setSelectedCoupons(coupons);
                  }}
                >
                  {coupon.code} <span>&#10060;</span>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="dashboard-panel">
          <label>
            <span className="filter-label">Select users:</span>

            <select
              onChange={(e) => {
                const name = e.target.value;

                const user = usersByCoupon?.find(
                  (u: any) => u.fullName === name
                );

                if (!user) {
                  return;
                }

                if (!selectedUsers.find((u) => u.id === user.id)) {
                  setSelectedUsers([
                    { id: user.id, name: user.fullName },
                    ...selectedUsers,
                  ]);
                }
              }}
            >
              <option key={0} value="All">
                {isLoadingCoupons ? "Loading..." : "All"}
              </option>

              {usersSortedByFullName?.map((u: any) => {
                const narrative = narrativeDataByCoupon?.find(
                  (_: any) => _.userId === u?.id
                );

                return (
                  <option
                    key={u.id}
                    value={u.fullName}
                    disabled={!narrative}
                    title={
                      !narrative
                        ? "This user doesn't have Lapin 2.0 narrative"
                        : ""
                    }
                  >
                    {u.fullName}
                  </option>
                );
              })}
            </select>
          </label>

          <div style={{ display: "flex", alignItems: "center" }}>
            {selectedUsers.map((u) => (
              <div key={u.id} style={{ marginLeft: 10 }}>
                {u.name},
              </div>
            ))}
          </div>
        </div>

        <div className="dashboard-panel">
          <Button size="lg" onClick={() => fetchCompatibilityMatrix()}>
            Get Compatibility Matrix
          </Button>

          <Button
            size="lg"
            style={{ marginLeft: 20 }}
            onClick={() => {
              setSelectedUsers([]);
              setSelectedCompatibility([]);
            }}
          >
            Reset Users
          </Button>
        </div>

        <div className="chart-row">
          <div>
            <h2>Compatibility Matrix:</h2>

            {!compatibilityMatrix && !isLoadingCompatibilityMatrix && (
              <div>
                Please, select users and click "Get Compatibility Matrix"
                button.
              </div>
            )}

            {isLoadingCompatibilityMatrix && <div>Loading...</div>}

            {compatibilityMatrix && (
              <table className="compatibility-table">
                <tbody>
                  <tr key={24324}>
                    <td></td>
                    {Object.keys(compatibilityMatrix)?.map((key: any) => {
                      const c = Object.entries(compatibilityMatrix[key]);

                      const currentUserId = c[0][0];

                      const userData: any = usersByCoupon?.find(
                        (f: any) => f.id == currentUserId
                      );

                      return <td key={userData.id}>{userData.fullName}</td>;
                    })}
                  </tr>

                  {Object.keys(compatibilityMatrix)?.map((key: any, i) => {
                    const c = Object.entries(compatibilityMatrix[key]);

                    const currentUserId = c[0][0];

                    const userData: any = usersByCoupon?.find(
                      (f: any) => f.id == currentUserId
                    );

                    const data: any = c[0][1];

                    return (
                      <tr
                        key={`${userData.id}-${currentUserId}`}
                        className={cn([
                          { "table-row-selected": selectedTableRowIndex === i },
                        ])}
                        onClick={() => {
                          setSelectedCompatibility(data);
                        }}
                      >
                        {data?.map((el: any, i: number) => {
                          return i === 0 ? (
                            <>
                              <td key={`${el.id}-1`}>{userData.fullName}</td>
                              <td
                                key={`${el.id}-2`}
                                style={{
                                  background: getBackground(el.compatibility),
                                }}
                                title={`Compatibility: ${el.compatibility}`}
                              />
                            </>
                          ) : (
                            <td
                              key={`${el.id}-1`}
                              style={{
                                background: getBackground(el.compatibility),
                              }}
                              title={`Compatibility: ${el.compatibility}`}
                            />
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>

        <div className="chart-row center-align-axis" style={{ marginTop: 50 }}>
          <h2>Individual Report: {targetUserForIndividualReport?.fullName}</h2>

          {isLoadingIndividualCompatibility && (
            <h3>Loading individual report...</h3>
          )}

          {compatibilityByUser?.map((c: any) => {
            return (
              <div key={c.user} style={{ marginBottom: 20 }}>
                <h3>
                  {c.user}: {c.score}
                </h3>

                <p>{c.description}</p>
              </div>
            );
          })}

          {!!selectedCompatibility.length ? (
            <ResponsiveContainer width="100%" height={700}>
              <ScatterChart
                margin={{
                  top: 70,
                  right: 70,
                  bottom: 70,
                  left: 70,
                }}
              >
                {/* <CartesianGrid /> */}
                <XAxis
                  // domain={[0, 100]}
                  type="number"
                  dataKey="compatibility"
                  name="compatibility"
                  // unit="cm"
                  tickLine={false}
                  label={{
                    value: "Compatibility",
                    position: "insideTopLeft",
                    dx: -10,
                    dy: -30,
                  }}
                />

                <YAxis
                  style={{ opacity: 0 }}
                  // dx={100}
                  // label={{
                  //   value: selectedUserForIndividualReport,
                  //   position: "top",
                  //   // dx: -10,
                  //   // dy: -30,
                  // }}
                  type="number"
                  dataKey="Yposition"
                  // name="id"
                  // unit="kg"
                  tick={false}
                  // tickLine={false}
                />

                <ZAxis
                  type="number"
                  dataKey="compatibility"
                  range={[15000, 15000]}
                />

                {/* <Tooltip
                  cursor={{
                    opacity: 0,
                    strokeDasharray: "3 3",
                  }}
                /> */}

                <Scatter
                  activeShape={args.activeShape}
                  activeIndex={args.activeIndex}
                  name="A school"
                  data={individualReportChartData}
                  // shape={<div>123</div>}
                >
                  <LabelList
                    dataKey="user"
                    position="right"
                    style={{ fontSize: "10px", fill: "#fff" }}
                    fontSize="12px"
                    width="12px"
                    dx={-100}
                  />

                  {/* {individualReportData.map((entry) => (
                      <Label
                        key={`cell-${entry.id}`}
                        style={{
                          fontSize: "10px",
                          background: "#fff",
                          color: entry.color,
                        }}
                      />
                    ))} */}

                  {individualReportChartData.map((entry) => (
                    <Cell key={`cell-${entry.id}`} fill={entry.color} />
                  ))}
                </Scatter>
              </ScatterChart>
            </ResponsiveContainer>
          ) : (
            <div>
              Please, click on the person in the Compatibility Matrix to view
              the Individual Report
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
